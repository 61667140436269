// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Override Bootstrap here
//

// Toggle global options
$enable-gradients: false;
$enable-shadows: false;

// Customize some defaults
$body-color: black;
$body-bg: white;
$border-radius: .4rem;

$primary: #063852;
$secondary: #011A27;
$danger: #E6df44;
$info: #f0810f;



//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

//@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/bootstrap";
$bootstrap-icons-font-dir: "~bootstrap-icons/font/fonts";
@import "~bootstrap-icons/font/bootstrap-icons.scss";
 
// Option 2
//
// Import just the styles you need. Note that some stylesheets are required no matter what.
/*
@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group"; // Requires forms
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/media";
@import "bootstrap/scss/close";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";


//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/jumbotron";
//@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
//@import "bootstrap/scss/list-group";
//@import "bootstrap/scss/toasts";
//@import "bootstrap/scss/modal"; // Requires transitions


//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/print";
//@import "bootstrap/scss/tables";

@import "bootstrap/scss/forms";
@import "bootstrap/scss/custom-forms";
*/
//
// Custom styles
//

.orange-border {
  border: 1.5px solid #f0810f; transform: scale(1.1)
}

.white-text {
  color: white;
}

nav.navbar {
  background-color: white;
}

.card-title {
  font-size: 2.5rem !important;
}

html {
  font-size: 0.9rem;
}


ul {
  list-style-type: none;
}

.circle1 {
  position: absolute;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50px;
  background: #f0810f;
  margin-left: -2rem;
  margin-top: -1rem;
  z-index: -3;
  opacity: 0.9;
}

.circle2 {
  position: absolute;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50px;
  background: #E6df44;
  margin-top: 5rem;
  margin-left: -3rem;
  z-index: -3;
  opacity: 0.9;
}

.circle3 {
  position: absolute;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50px;
  background: #063852;
  margin-top: -2.5rem;
  margin-left: 6rem;
  z-index: -3;
  opacity: 0.9;
}

.cta-btn {
  margin-top: 10% !important;
}

.display-4 {
  font-size: 2.5rem !important;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1rem;
  }
  .video-container {
    width: 75%;
  }
  .circle2 {
    margin-top: 0rem;
    margin-left: 0rem;
  }

  .cta-btn {
    margin-left: -60% !important;
    margin-top: 10% !important;
  }

}

@include media-breakpoint-up(md) {
  html {
    font-size: 1rem;
  }

  .cta-btn {
    margin-left: -40% !important;
    margin-top: 10% !important;
  }
  
  .circle1 {
    margin-left: -2rem;
    margin-top: -1.5rem;
    z-index: -3;
    opacity: 0.9;
  }

  .circle2 {
    margin-top: 3rem;
    margin-left: 3rem;
  }

  .circle3 {
    margin-left: 6rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1rem;
  }

  h2 {
    font-size: 2.5rem !important;
  }

  .typewrite {
    font-size: 2rem !important;
  }

  .cta-btn {
    margin-left: -60% !important;
    margin-top: 0 !important;
  }

  .circle1 {
    margin-left: -1.5rem;
    margin-top: -1rem;
  }
  
  .circle2 {
    margin-top: -8rem;
    margin-right: 3rem;
  }
  
  .circle3 {
    margin-top: -7rem;
    margin-left: 14rem;
  }
  .video-container {
    width: 60%;
  }
}

body {
  padding: 3rem;
  font-family: 'Nunito', sans-serif !important;
  color: black;
}

i.bi {
  fill: #f0810f;
  color: #f0810f !important;
}

.typewrite {
  color: #f0810f !important;
}

.flying-paper {
  margin-left: -4rem;
  margin-top: 3rem;
  position: absolute;
}

.card-header {
  background: rgba(240, 129, 15, 0.03) !important;
}

.cmb {
  margin-bottom: 2rem !important;
}

.cmt {
  margin-top: 2rem !important;
}

.card {
  flex-direction: column;
  min-width: 0;
  color: #000;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  right: 0;
}

.learn-more {
  text-decoration: none;
  color: #000;
  margin-top: 8px
}

.learn-more:hover {
  text-decoration: none;
  color: blue;
  margin-top: 8px
}

.screenshot img {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.bi-check-circle {
  transform: translateY(-15%);
}

.rounded-social-buttons svg {
  color:#6c757d !important;
}
